import { useCallback } from "react";

import { useParams } from "react-router-dom";
import { z } from "zod";

import { useApiAccountSetup } from "@/api";
import { Form, FormInput } from "@/components/form";
import { useToast } from "@/components/ui/use-toast";

import { useAuth } from "../hooks/useAuth";
import { AuthLayout } from "../layout/AuthLayout";

const formSchema = z
  .object({
    password1: z.string().min(1, "Required"),
    password2: z.string().min(1, "Required"),
  })
  .refine((schema) => schema.password1 === schema.password2, {
    message: "Passwords do not match",
    path: ["password2"],
  });

export type FormSchemaType = z.infer<typeof formSchema>;

export const AccountSetup = () => {
  const { uid, token } = useParams();
  const { setToken } = useAuth();
  const { toast } = useToast();
  const { mutateAsync: setPassword } = useApiAccountSetup();

  const handleSubmit = useCallback(
    async (data: FormSchemaType) => {
      const response = await setPassword({
        data: {
          uid: uid!,
          token: token!,
          ...data,
        },
      });
      const authToken = response.data.token;
      setToken(authToken, "/");

      toast({
        title: "Your password has been set",
        description: "Please use this password to login to your account.",
      });
    },
    [setPassword, setToken, toast, token, uid]
  );

  return (
    <AuthLayout
      heading="Welcome to MyChartered"
      subheading="Please set your password that you will use to login in the future."
      title="Welcome to MyChartered"
    >
      <Form<FormSchemaType>
        className="space-y-4"
        formSchema={formSchema}
        submitButtonClassName="w-full"
        submitText="Set Password"
        to="/"
        onSubmit={handleSubmit}
      >
        {({ control }) => (
          <>
            <FormInput
              control={control}
              label="Password"
              name="password1"
              placeholder="Please enter a password"
              type="password"
            />
            <FormInput
              control={control}
              label="Confirm Password"
              name="password2"
              placeholder="Please re-enter your password"
              type="password"
            />
          </>
        )}
      </Form>
    </AuthLayout>
  );
};
