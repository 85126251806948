import { useCallback, useState } from "react";

import { z } from "zod";

import { useApiChangePassword } from "@/api";
import { Header } from "@/components/elements";
import { Form, FormInput } from "@/components/form";
import { PageLayout } from "@/components/layout";
import { useToast } from "@/components/ui/use-toast";

const formSchema = z
  .object({
    current_password: z.string().min(1, "Required"),
    new_password1: z.string().min(1, "Required"),
    new_password2: z.string().min(1, "Required"),
  })
  .refine((schema) => schema.new_password1 === schema.new_password2, {
    message: "Passwords do not match",
    path: ["password2"],
  });

export type FormSchemaType = z.infer<typeof formSchema>;

export const ChangePassword = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { toast } = useToast();
  const { mutateAsync: resetPassword } = useApiChangePassword();

  const handleSubmit = useCallback(
    async (data: FormSchemaType) => {
      await resetPassword({ data });
      toast({
        title: "Your password has been changed",
      });
      setIsSubmitted(true);
    },
    [resetPassword, setIsSubmitted, toast]
  );

  return (
    <PageLayout
      className="max-w-3xl px-4 sm:px-6 lg:px-8"
      title="Change Your Password"
    >
      {isSubmitted ? (
        <Submitted />
      ) : (
        <>
          <Header
            subtitle="Set a new password that you will use to login."
            title="Change Your Password"
          />
          <Form<FormSchemaType>
            className="mt-6 space-y-4 bg-white border rounded-md p-4"
            cleanFormOnSuccess={true}
            formSchema={formSchema}
            submitText="Change Password"
            onSubmit={handleSubmit}
          >
            {({ control }) => (
              <>
                <FormInput
                  control={control}
                  label="Current Password"
                  name="current_password"
                  placeholder="Please enter your current password"
                  type="password"
                />
                <FormInput
                  control={control}
                  label="New Password"
                  name="new_password1"
                  placeholder="Please enter a new password"
                  type="password"
                />
                <FormInput
                  control={control}
                  label="Confirm New Password"
                  name="new_password2"
                  placeholder="Please re-enter your new password"
                  type="password"
                />
              </>
            )}
          </Form>
        </>
      )}
    </PageLayout>
  );
};

const Submitted = () => {
  return (
    <div className="bg-white border rounded-md p-6">
      <Header
        subtitle="Please use this password when you next login."
        title="Your password has been changed"
      />
    </div>
  );
};