import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import type { InputProps as BaseInputProps } from "@/components/ui/input";
import { Input as BaseInput } from "@/components/ui/input";

import type { ControlledFieldProps } from "..";

type FormInputProps = ControlledFieldProps & BaseInputProps;

export const FormInput = ({
  control,
  name,
  label,
  description,
  ...props
}: FormInputProps) => {
  return (
    <FormField
      control={control}
      name={name}
      // eslint-disable-next-line react/jsx-no-bind
      render={({ field }) => (
        <FormItem>
          {label && <FormLabel>{label}</FormLabel>}
          <FormControl>
            <BaseInput {...field} {...props} />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
