import { ExclamationTriangleIcon } from "@radix-ui/react-icons";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { cn } from "@/lib/utils";

type FormErrorProps = {
  error: string;
  className?: string;
};

export const FormError = ({ error, className }: FormErrorProps) => {
  return (
    <Alert className={cn("font-light", className)} variant="destructive">
      <AlertDescription className="flex space-x-4">
        <ExclamationTriangleIcon className="my-auto mr-2 h-4 w-4" />
        {error}
      </AlertDescription>
    </Alert>
  );
};
