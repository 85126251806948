import type { Path, UseFormReturn } from "react-hook-form";

export const setFormErrors = <TFormValues = unknown>(
  form: UseFormReturn,
  message: string | undefined,
  fieldErrors: Record<string, string | string[]>
) => {
  if (!fieldErrors) {
    form.setError("root", {
      message: message,
    });
  }

  if ("non_field_errors" in fieldErrors) {
    form.setError("root", {
      message: fieldErrors["non_field_errors"].toString(),
    });
  }

  for (const [fieldName, errorMessage] of Object.entries(fieldErrors)) {
    form.setError(fieldName as Path<TFormValues>, {
      message: errorMessage.toString(),
    });
  }
};
