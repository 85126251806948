import { z } from "zod";

import type { UserDetailOutput } from "@/api";
import type { FormProps } from "@/components/form";
import { Form, FormInput, FormSelect } from "@/components/form";
import { useAuth } from "@/features/auth";
import { ChartershipCombobox } from "@/features/chartership";

import { UserCombobox } from "./UserCombobox";

const formSchema = z
  .object({
    first_name: z.string().min(1, { message: "First name is required" }),
    last_name: z.string().min(1, { message: "Last name is required" }),
    email: z.string().email().min(1, { message: "Email is required" }),
    account_type: z.string().min(1, { message: "Account type is required" }),
    chartership_id: z.string().optional().nullable(),
    mentor_id: z.string().optional().nullable(),
  })
  // User must have chartership.
  .refine(
    (data) => {
      if (data.account_type === "USER") {
        return data.chartership_id != undefined;
      }
      return true;
    },
    {
      message: "Users require a chartership to be assigned.",
      path: ["chartership_id"],
    }
  )
  // Cannot have mentor without chartership.
  .refine(
    (data) => {
      if (data.mentor_id != undefined) {
        return data.chartership_id != undefined;
      }
      return true;
    },
    {
      message: "Chartership is required when a mentor is assigned.",
      path: ["chartership_id"],
    }
  );

export type FormSchemaType = z.infer<typeof formSchema>;

const accountTypeOptions = [
  {
    value: "USER",
    label: "User",
  },
  {
    value: "MENTOR",
    label: "Mentor",
  },
  {
    value: "MANAGER",
    label: "Manager",
  },
];

type UserFormProps = {
  user?: UserDetailOutput;
} & Pick<FormProps<FormSchemaType>, "onSubmit" | "to" | "submitToast">;

export const UserForm = ({
  user,
  onSubmit,
  to,
  submitToast,
}: UserFormProps) => {
  const { user: currentUser } = useAuth();

  return (
    <Form<FormSchemaType>
      className="max-w-2xl"
      formSchema={formSchema}
      options={{
        defaultValues: user,
        disabled: currentUser?.account_type !== "MANAGER",
      }}
      submitToast={submitToast}
      to={to}
      onSubmit={onSubmit}
    >
      {({ control, setValue }) => (
        <div className="grid sm:grid-cols-2 gap-x-4 gap-y-6">
          <div className="col-span-1">
            <FormInput control={control} label="First name" name="first_name" />
          </div>
          <div className="col-span-1">
            <FormInput control={control} label="Last name" name="last_name" />
          </div>
          <div className="col-span-2">
            <FormInput control={control} label="Email" name="email" />
          </div>
          <div className="col-span-1">
            <FormSelect
              control={control}
              label="Account Type"
              name="account_type"
              options={accountTypeOptions}
              placeholder="Select account type..."
            />
          </div>
          <div className="col-span-1" />
          <div className="col-span-1">
            <ChartershipCombobox
              allowNull={true}
              control={control}
              label="Chartership"
              name="chartership_id"
              setValue={setValue}
            />
          </div>
          <div className="col-span-1">
            <UserCombobox
              allowNull={true}
              control={control}
              label="Mentor"
              name="mentor_id"
              params={{ account_type: "MENTOR,MANAGER" }}
              setValue={setValue}
            />
          </div>
        </div>
      )}
    </Form>
  );
};
