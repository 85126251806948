import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";

import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";

import type { UncontrolledFieldProps } from "../types";
import type { OptionsType } from "../types";

export type FormComboboxProps = UncontrolledFieldProps & {
  options: OptionsType;
  isLoading?: boolean;
};

export const FormCombobox = ({
  control,
  setValue,
  name,
  label,
  description,
  options,
  isLoading = false,
  placeholder = "Select...",
  allowNull,
}: FormComboboxProps) => {
  return (
    <FormField
      control={control}
      name={name}
      // eslint-disable-next-line react/jsx-no-bind
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <div className="flex justify-between content-center">
            {label && <FormLabel>{label}</FormLabel>}
            {allowNull && field.value && !field.disabled && (
              <div
                className="leading-none text-sm font-light text-right hover:underline cursor-pointer"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => setValue(name, null)}
              >
                clear
              </div>
            )}
          </div>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  className="justify-between bg-white px-3 font-light"
                  disabled={isLoading || field.disabled}
                  role="combobox"
                  variant="outline"
                >
                  <span className="overflow-y-hidden text-sm font-light">
                    {field.value
                      ? isLoading
                        ? "Loading..."
                        : options?.find((item) => item.value === field.value)
                            ?.label
                      : placeholder}
                  </span>
                  <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="p-0">
              <Command>
                <CommandInput className="h-9" placeholder="Search..." />
                <CommandEmpty>No matches found.</CommandEmpty>
                <CommandGroup className="max-h-64 overflow-y-auto">
                  {options?.map((item) => (
                    <CommandItem
                      className="hover:bg-gray-100 focus:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-white"
                      key={item.value}
                      unselectable="on"
                      value={item.value}
                      // eslint-disable-next-line react/jsx-no-bind
                      onSelect={() => {
                        setValue(name, item.value);
                      }}
                    >
                      {item.label}
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4",
                          field.value === item.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
