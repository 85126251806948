import { Fragment, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import {
  ArchiveIcon,
  BookmarkIcon,
  ChatBubbleIcon,
  Cross1Icon,
  DashboardIcon,
  HamburgerMenuIcon,
  MagicWandIcon,
  Pencil2Icon,
  PersonIcon,
  PieChartIcon,
  QuestionMarkIcon,
  StarIcon,
  UpdateIcon,
} from "@radix-ui/react-icons";
import { ErrorBoundary } from "@sentry/react";
import { KeyRoundIcon, LogOutIcon } from "lucide-react";
import { Link, NavLink } from "react-router-dom";

import type { UserDetailMeOutput } from "@/api";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { formatName } from "@/features/accounts";
import { useAuth } from "@/features/auth";

import { Logo } from "../elements/Logo";
import { Button } from "../ui/button";
import { Separator } from "../ui/separator";
import { ErrorFallback } from "./ErrorFallback";

const navigation = [
  {
    isHidden: (user: UserDetailMeOutput) => user.chartership === null,
    disabledText: "You must be assigned a chartership to view this page.",
    links: [
      {
        name: "Dashboard",
        to: "/dashboard",
        icon: DashboardIcon,
      },
      {
        name: "Activities",
        to: "/activities",
        icon: Pencil2Icon,
      },
      {
        name: "Comments",
        to: "/comments",
        icon: ChatBubbleIcon,
      },
      {
        name: "Chartership",
        to: "/chartership",
        icon: BookmarkIcon,
      },
      {
        name: "Courses & CPD",
        to: "/courses",
        icon: ArchiveIcon,
      },
    ],
  },
  {
    isHidden: (user: UserDetailMeOutput) => user.account_type === "USER",
    disabledText: undefined,
    links: [
      {
        name: "My Mentees",
        to: "/mentees",
        icon: StarIcon,
      },
      {
        name: "Updates",
        to: "/updates",
        icon: UpdateIcon,
      },
      {
        name: "Reports",
        to: "/reports",
        icon: PieChartIcon,
      },
      {
        name: "Users",
        to: "/users",
        icon: PersonIcon,
      },
    ],
  },
];

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user, logout } = useAuth();

  return (
    <>
      <div>
        <Transition.Root as={Fragment} show={sidebarOpen}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        className="-m-2.5 p-2.5"
                        type="button"
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <Cross1Icon
                          aria-hidden="true"
                          className="h-6 w-6 text-white"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 py-4">
                    <Logo className="h-24" />
                    <div className="-mt-6 flex grow flex-col">
                      <MenuItems logout={logout} user={user!} />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-64 lg:flex-col border-r">
          <div className="flex grow flex-col overflow-y-auto bg-white px-6 pb-2">
            <div className="mt-4">
              <Logo className="h-24" />
            </div>
            <MenuItems logout={logout} user={user!} />
          </div>
        </div>

        <div className="lg:hidden sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 sm:gap-x-6 sm:px-6 lg:px-8">
          <div className="flex-1">
            <Logo className="h-24" />
          </div>
          <button
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <HamburgerMenuIcon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>

        <div className="lg:pl-64">
          <main className="py-6 lg:py-10">
            <ErrorBoundary fallback={<ErrorFallback />}>
              {children}
            </ErrorBoundary>
          </main>
        </div>
      </div>
    </>
  );
};

const MenuItems = ({
  user,
  logout,
}: {
  user: UserDetailMeOutput;
  logout: () => void;
}) => {
  return (
    <nav className="flex flex-1 flex-col">
      <ul className="-mx-2 flex flex-1 flex-col gap-y-4" role="list">
        <li>
          {navigation
            .filter((section) => !section.isHidden(user))
            .map((section, idx) => {
              return (
                <div className="mt-4" key={idx}>
                  {idx !== 0 && <Separator className="mb-4" />}
                  <ul className="space-y-0" role="list">
                    {section.links.map((link) => (
                      <li key={link.name}>
                        <NavLink to={link.to}>
                          {({ isActive }) => (
                            <Button
                              className="w-full justify-start"
                              variant={isActive ? "secondary" : "ghost"}
                            >
                              <link.icon className="mr-2 h-4 w-4" />
                              {link.name}
                            </Button>
                          )}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
        </li>
        <li className="mt-auto">
          <NavLink to="/support/feedback">
            {({ isActive }) => (
              <Button
                className="w-full justify-start"
                variant={isActive ? "secondary" : "ghost"}
              >
                <MagicWandIcon className="mr-2 h-4 w-4" />
                Suggest Feedback
              </Button>
            )}
          </NavLink>
          <NavLink to="/support/help">
            {({ isActive }) => (
              <Button
                className="w-full justify-start"
                variant={isActive ? "secondary" : "ghost"}
              >
                <QuestionMarkIcon className="mr-2 h-4 w-4" />
                Help & Support
              </Button>
            )}
          </NavLink>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button className="w-full justify-start" variant="ghost">
                <PersonIcon className="mr-2 h-4 w-4" />
                My Account
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" className="w-56" side="top">
              <DropdownMenuGroup>
                <div className="px-2 py-1 text-sm flex flex-col">
                  <span className="font-semibold">{formatName(user)}</span>
                  <span>{user?.email}</span>
                </div>
              </DropdownMenuGroup>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <Link to="/profile/change-password">
                  <DropdownMenuItem>
                    <KeyRoundIcon className="mr-2 h-4 w-4" />
                    <span>Change Password</span>
                  </DropdownMenuItem>
                </Link>
              </DropdownMenuGroup>
              <DropdownMenuItem onClick={logout}>
                <LogOutIcon className="mr-2 h-4 w-4" />
                <span>Logout</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </li>
      </ul>
    </nav>
  );
};
