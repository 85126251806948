/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 */
import * as axios from 'axios';
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import {
  useMutation,
  useQuery
} from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from 'react-query'
import type {
  AccountSetupInput,
  AccountSetupOutput,
  ActivityCommentCreateInput,
  ActivityCommentCreateOutput,
  ActivityCreateInput,
  ActivityCreateOutput,
  ActivityDetailOutput,
  ActivityExportInput,
  ActivityUpdateInput,
  ActivityUpdateOutput,
  ApiActivityCommentList200,
  ApiActivityCommentRecentList200,
  ApiActivityCommentRecentListParams,
  ApiActivityListAll200,
  ApiActivityListAllParams,
  ApiActivityListCurrentUser200,
  ApiActivityListCurrentUserParams,
  ApiActivityLogList200,
  ApiChartershipActivityCurrentUserList200,
  ApiChartershipActivityCurrentUserListParams,
  ApiChartershipActivityListAll200,
  ApiChartershipActivityListAllParams,
  ApiChartershipList200,
  ApiChartershipListParams,
  ApiProfessionalBodyCourseList200,
  ApiProfessionalBodyCourseListParams,
  ApiUserList200,
  ApiUserListParams,
  ChangePasswordInput,
  ChartershipActivityDetailOutput,
  ChartershipDetailOutput,
  CompetencyDetailOutput,
  ContactCreateInput,
  EmailLoginInput,
  EmailLoginOutput,
  ExternalLinkMetricLogViewInput,
  ForgotPasswordInput,
  MetricsDashboardCIOBSteppingStonesOutput,
  MetricsReportCoreOutput,
  ResetPasswordInput,
  UserCreateInput,
  UserCreateOutput,
  UserDetailMeOutput,
  UserDetailOutput,
  UserFeedbackCreateInput,
  UserHelpRequestCreateInput,
  UserUpdateInput,
  UserUpdateOutput
} from '.././model'



export const apiUserList = (
    params?: ApiUserListParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ApiUserList200>> => {
    
    return axios.default.get(
      `/api/users/`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getApiUserListQueryKey = (params?: ApiUserListParams,) => {
    return [`/api/users/`, ...(params ? [params]: [])] as const;
    }

    
export const getApiUserListQueryOptions = <TData = Awaited<ReturnType<typeof apiUserList>>, TError = AxiosError<unknown>>(params?: ApiUserListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiUserList>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getApiUserListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiUserList>>> = ({ signal }) => apiUserList(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiUserList>>, TError, TData> & { queryKey: QueryKey }
}

export type ApiUserListQueryResult = NonNullable<Awaited<ReturnType<typeof apiUserList>>>
export type ApiUserListQueryError = AxiosError<unknown>

export const useApiUserList = <TData = Awaited<ReturnType<typeof apiUserList>>, TError = AxiosError<unknown>>(
 params?: ApiUserListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiUserList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiUserListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const apiUserDetail = (
    userId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserDetailOutput>> => {
    
    return axios.default.get(
      `/api/users/${userId}/`,options
    );
  }


export const getApiUserDetailQueryKey = (userId: string,) => {
    return [`/api/users/${userId}/`] as const;
    }

    
export const getApiUserDetailQueryOptions = <TData = Awaited<ReturnType<typeof apiUserDetail>>, TError = AxiosError<unknown>>(userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiUserDetail>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getApiUserDetailQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiUserDetail>>> = ({ signal }) => apiUserDetail(userId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiUserDetail>>, TError, TData> & { queryKey: QueryKey }
}

export type ApiUserDetailQueryResult = NonNullable<Awaited<ReturnType<typeof apiUserDetail>>>
export type ApiUserDetailQueryError = AxiosError<unknown>

export const useApiUserDetail = <TData = Awaited<ReturnType<typeof apiUserDetail>>, TError = AxiosError<unknown>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiUserDetail>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiUserDetailQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const apiUserDetailMe = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserDetailMeOutput>> => {
    
    return axios.default.get(
      `/api/users/me/`,options
    );
  }


export const getApiUserDetailMeQueryKey = () => {
    return [`/api/users/me/`] as const;
    }

    
export const getApiUserDetailMeQueryOptions = <TData = Awaited<ReturnType<typeof apiUserDetailMe>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiUserDetailMe>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getApiUserDetailMeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiUserDetailMe>>> = ({ signal }) => apiUserDetailMe({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiUserDetailMe>>, TError, TData> & { queryKey: QueryKey }
}

export type ApiUserDetailMeQueryResult = NonNullable<Awaited<ReturnType<typeof apiUserDetailMe>>>
export type ApiUserDetailMeQueryError = AxiosError<unknown>

export const useApiUserDetailMe = <TData = Awaited<ReturnType<typeof apiUserDetailMe>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiUserDetailMe>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiUserDetailMeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Return the current user's activities for a given chartership activity.
 */
export const apiActivityListCurrentUser = (
    chartershipActivityId: string,
    params?: ApiActivityListCurrentUserParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ApiActivityListCurrentUser200>> => {
    
    return axios.default.get(
      `/api/activities/${chartershipActivityId}/current-user/`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getApiActivityListCurrentUserQueryKey = (chartershipActivityId: string,
    params?: ApiActivityListCurrentUserParams,) => {
    return [`/api/activities/${chartershipActivityId}/current-user/`, ...(params ? [params]: [])] as const;
    }

    
export const getApiActivityListCurrentUserQueryOptions = <TData = Awaited<ReturnType<typeof apiActivityListCurrentUser>>, TError = AxiosError<unknown>>(chartershipActivityId: string,
    params?: ApiActivityListCurrentUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiActivityListCurrentUser>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getApiActivityListCurrentUserQueryKey(chartershipActivityId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiActivityListCurrentUser>>> = ({ signal }) => apiActivityListCurrentUser(chartershipActivityId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(chartershipActivityId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiActivityListCurrentUser>>, TError, TData> & { queryKey: QueryKey }
}

export type ApiActivityListCurrentUserQueryResult = NonNullable<Awaited<ReturnType<typeof apiActivityListCurrentUser>>>
export type ApiActivityListCurrentUserQueryError = AxiosError<unknown>

export const useApiActivityListCurrentUser = <TData = Awaited<ReturnType<typeof apiActivityListCurrentUser>>, TError = AxiosError<unknown>>(
 chartershipActivityId: string,
    params?: ApiActivityListCurrentUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiActivityListCurrentUser>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiActivityListCurrentUserQueryOptions(chartershipActivityId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Return the all of the activities the current user has access to.
 */
export const apiActivityListAll = (
    params?: ApiActivityListAllParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ApiActivityListAll200>> => {
    
    return axios.default.get(
      `/api/activities/all/`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getApiActivityListAllQueryKey = (params?: ApiActivityListAllParams,) => {
    return [`/api/activities/all/`, ...(params ? [params]: [])] as const;
    }

    
export const getApiActivityListAllQueryOptions = <TData = Awaited<ReturnType<typeof apiActivityListAll>>, TError = AxiosError<unknown>>(params?: ApiActivityListAllParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiActivityListAll>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getApiActivityListAllQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiActivityListAll>>> = ({ signal }) => apiActivityListAll(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiActivityListAll>>, TError, TData> & { queryKey: QueryKey }
}

export type ApiActivityListAllQueryResult = NonNullable<Awaited<ReturnType<typeof apiActivityListAll>>>
export type ApiActivityListAllQueryError = AxiosError<unknown>

export const useApiActivityListAll = <TData = Awaited<ReturnType<typeof apiActivityListAll>>, TError = AxiosError<unknown>>(
 params?: ApiActivityListAllParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiActivityListAll>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiActivityListAllQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Return an activity if the user has permissions to view it.
 */
export const apiActivityDetail = (
    activityId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ActivityDetailOutput>> => {
    
    return axios.default.get(
      `/api/activities/${activityId}/`,options
    );
  }


export const getApiActivityDetailQueryKey = (activityId: string,) => {
    return [`/api/activities/${activityId}/`] as const;
    }

    
export const getApiActivityDetailQueryOptions = <TData = Awaited<ReturnType<typeof apiActivityDetail>>, TError = AxiosError<unknown>>(activityId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiActivityDetail>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getApiActivityDetailQueryKey(activityId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiActivityDetail>>> = ({ signal }) => apiActivityDetail(activityId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(activityId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiActivityDetail>>, TError, TData> & { queryKey: QueryKey }
}

export type ApiActivityDetailQueryResult = NonNullable<Awaited<ReturnType<typeof apiActivityDetail>>>
export type ApiActivityDetailQueryError = AxiosError<unknown>

export const useApiActivityDetail = <TData = Awaited<ReturnType<typeof apiActivityDetail>>, TError = AxiosError<unknown>>(
 activityId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiActivityDetail>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiActivityDetailQueryOptions(activityId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns Activities for the user which have comments associated with them.
 */
export const apiActivityCommentRecentList = (
    params?: ApiActivityCommentRecentListParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ApiActivityCommentRecentList200>> => {
    
    return axios.default.get(
      `/api/comments/recent/`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getApiActivityCommentRecentListQueryKey = (params?: ApiActivityCommentRecentListParams,) => {
    return [`/api/comments/recent/`, ...(params ? [params]: [])] as const;
    }

    
export const getApiActivityCommentRecentListQueryOptions = <TData = Awaited<ReturnType<typeof apiActivityCommentRecentList>>, TError = AxiosError<unknown>>(params?: ApiActivityCommentRecentListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiActivityCommentRecentList>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getApiActivityCommentRecentListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiActivityCommentRecentList>>> = ({ signal }) => apiActivityCommentRecentList(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiActivityCommentRecentList>>, TError, TData> & { queryKey: QueryKey }
}

export type ApiActivityCommentRecentListQueryResult = NonNullable<Awaited<ReturnType<typeof apiActivityCommentRecentList>>>
export type ApiActivityCommentRecentListQueryError = AxiosError<unknown>

export const useApiActivityCommentRecentList = <TData = Awaited<ReturnType<typeof apiActivityCommentRecentList>>, TError = AxiosError<unknown>>(
 params?: ApiActivityCommentRecentListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiActivityCommentRecentList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiActivityCommentRecentListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const apiActivityCommentList = (
    activityId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ApiActivityCommentList200>> => {
    
    return axios.default.get(
      `/api/comments/${activityId}/`,options
    );
  }


export const getApiActivityCommentListQueryKey = (activityId: string,) => {
    return [`/api/comments/${activityId}/`] as const;
    }

    
export const getApiActivityCommentListQueryOptions = <TData = Awaited<ReturnType<typeof apiActivityCommentList>>, TError = AxiosError<unknown>>(activityId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiActivityCommentList>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getApiActivityCommentListQueryKey(activityId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiActivityCommentList>>> = ({ signal }) => apiActivityCommentList(activityId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(activityId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiActivityCommentList>>, TError, TData> & { queryKey: QueryKey }
}

export type ApiActivityCommentListQueryResult = NonNullable<Awaited<ReturnType<typeof apiActivityCommentList>>>
export type ApiActivityCommentListQueryError = AxiosError<unknown>

export const useApiActivityCommentList = <TData = Awaited<ReturnType<typeof apiActivityCommentList>>, TError = AxiosError<unknown>>(
 activityId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiActivityCommentList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiActivityCommentListQueryOptions(activityId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const apiActivityLogList = (
    activityId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ApiActivityLogList200>> => {
    
    return axios.default.get(
      `/api/logs/${activityId}/`,options
    );
  }


export const getApiActivityLogListQueryKey = (activityId: string,) => {
    return [`/api/logs/${activityId}/`] as const;
    }

    
export const getApiActivityLogListQueryOptions = <TData = Awaited<ReturnType<typeof apiActivityLogList>>, TError = AxiosError<unknown>>(activityId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiActivityLogList>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getApiActivityLogListQueryKey(activityId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiActivityLogList>>> = ({ signal }) => apiActivityLogList(activityId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(activityId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiActivityLogList>>, TError, TData> & { queryKey: QueryKey }
}

export type ApiActivityLogListQueryResult = NonNullable<Awaited<ReturnType<typeof apiActivityLogList>>>
export type ApiActivityLogListQueryError = AxiosError<unknown>

export const useApiActivityLogList = <TData = Awaited<ReturnType<typeof apiActivityLogList>>, TError = AxiosError<unknown>>(
 activityId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiActivityLogList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiActivityLogListQueryOptions(activityId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const apiChartershipList = (
    params?: ApiChartershipListParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ApiChartershipList200>> => {
    
    return axios.default.get(
      `/api/chartership/`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getApiChartershipListQueryKey = (params?: ApiChartershipListParams,) => {
    return [`/api/chartership/`, ...(params ? [params]: [])] as const;
    }

    
export const getApiChartershipListQueryOptions = <TData = Awaited<ReturnType<typeof apiChartershipList>>, TError = AxiosError<unknown>>(params?: ApiChartershipListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiChartershipList>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getApiChartershipListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiChartershipList>>> = ({ signal }) => apiChartershipList(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiChartershipList>>, TError, TData> & { queryKey: QueryKey }
}

export type ApiChartershipListQueryResult = NonNullable<Awaited<ReturnType<typeof apiChartershipList>>>
export type ApiChartershipListQueryError = AxiosError<unknown>

export const useApiChartershipList = <TData = Awaited<ReturnType<typeof apiChartershipList>>, TError = AxiosError<unknown>>(
 params?: ApiChartershipListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiChartershipList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiChartershipListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const apiChartershipDetail = (
    chartershipId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ChartershipDetailOutput>> => {
    
    return axios.default.get(
      `/api/chartership/${chartershipId}/`,options
    );
  }


export const getApiChartershipDetailQueryKey = (chartershipId: string,) => {
    return [`/api/chartership/${chartershipId}/`] as const;
    }

    
export const getApiChartershipDetailQueryOptions = <TData = Awaited<ReturnType<typeof apiChartershipDetail>>, TError = AxiosError<unknown>>(chartershipId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiChartershipDetail>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getApiChartershipDetailQueryKey(chartershipId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiChartershipDetail>>> = ({ signal }) => apiChartershipDetail(chartershipId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(chartershipId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiChartershipDetail>>, TError, TData> & { queryKey: QueryKey }
}

export type ApiChartershipDetailQueryResult = NonNullable<Awaited<ReturnType<typeof apiChartershipDetail>>>
export type ApiChartershipDetailQueryError = AxiosError<unknown>

export const useApiChartershipDetail = <TData = Awaited<ReturnType<typeof apiChartershipDetail>>, TError = AxiosError<unknown>>(
 chartershipId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiChartershipDetail>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiChartershipDetailQueryOptions(chartershipId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const apiChartershipActivityListAll = (
    params?: ApiChartershipActivityListAllParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ApiChartershipActivityListAll200>> => {
    
    return axios.default.get(
      `/api/chartership/activities/`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getApiChartershipActivityListAllQueryKey = (params?: ApiChartershipActivityListAllParams,) => {
    return [`/api/chartership/activities/`, ...(params ? [params]: [])] as const;
    }

    
export const getApiChartershipActivityListAllQueryOptions = <TData = Awaited<ReturnType<typeof apiChartershipActivityListAll>>, TError = AxiosError<unknown>>(params?: ApiChartershipActivityListAllParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiChartershipActivityListAll>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getApiChartershipActivityListAllQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiChartershipActivityListAll>>> = ({ signal }) => apiChartershipActivityListAll(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiChartershipActivityListAll>>, TError, TData> & { queryKey: QueryKey }
}

export type ApiChartershipActivityListAllQueryResult = NonNullable<Awaited<ReturnType<typeof apiChartershipActivityListAll>>>
export type ApiChartershipActivityListAllQueryError = AxiosError<unknown>

export const useApiChartershipActivityListAll = <TData = Awaited<ReturnType<typeof apiChartershipActivityListAll>>, TError = AxiosError<unknown>>(
 params?: ApiChartershipActivityListAllParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiChartershipActivityListAll>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiChartershipActivityListAllQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const apiChartershipActivityCurrentUserList = (
    params?: ApiChartershipActivityCurrentUserListParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ApiChartershipActivityCurrentUserList200>> => {
    
    return axios.default.get(
      `/api/chartership/activities/current-user/`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getApiChartershipActivityCurrentUserListQueryKey = (params?: ApiChartershipActivityCurrentUserListParams,) => {
    return [`/api/chartership/activities/current-user/`, ...(params ? [params]: [])] as const;
    }

    
export const getApiChartershipActivityCurrentUserListQueryOptions = <TData = Awaited<ReturnType<typeof apiChartershipActivityCurrentUserList>>, TError = AxiosError<unknown>>(params?: ApiChartershipActivityCurrentUserListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiChartershipActivityCurrentUserList>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getApiChartershipActivityCurrentUserListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiChartershipActivityCurrentUserList>>> = ({ signal }) => apiChartershipActivityCurrentUserList(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiChartershipActivityCurrentUserList>>, TError, TData> & { queryKey: QueryKey }
}

export type ApiChartershipActivityCurrentUserListQueryResult = NonNullable<Awaited<ReturnType<typeof apiChartershipActivityCurrentUserList>>>
export type ApiChartershipActivityCurrentUserListQueryError = AxiosError<unknown>

export const useApiChartershipActivityCurrentUserList = <TData = Awaited<ReturnType<typeof apiChartershipActivityCurrentUserList>>, TError = AxiosError<unknown>>(
 params?: ApiChartershipActivityCurrentUserListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiChartershipActivityCurrentUserList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiChartershipActivityCurrentUserListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const apiChartershipActivityDetail = (
    activityId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ChartershipActivityDetailOutput>> => {
    
    return axios.default.get(
      `/api/chartership/activities/${activityId}/`,options
    );
  }


export const getApiChartershipActivityDetailQueryKey = (activityId: string,) => {
    return [`/api/chartership/activities/${activityId}/`] as const;
    }

    
export const getApiChartershipActivityDetailQueryOptions = <TData = Awaited<ReturnType<typeof apiChartershipActivityDetail>>, TError = AxiosError<unknown>>(activityId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiChartershipActivityDetail>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getApiChartershipActivityDetailQueryKey(activityId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiChartershipActivityDetail>>> = ({ signal }) => apiChartershipActivityDetail(activityId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(activityId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiChartershipActivityDetail>>, TError, TData> & { queryKey: QueryKey }
}

export type ApiChartershipActivityDetailQueryResult = NonNullable<Awaited<ReturnType<typeof apiChartershipActivityDetail>>>
export type ApiChartershipActivityDetailQueryError = AxiosError<unknown>

export const useApiChartershipActivityDetail = <TData = Awaited<ReturnType<typeof apiChartershipActivityDetail>>, TError = AxiosError<unknown>>(
 activityId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiChartershipActivityDetail>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiChartershipActivityDetailQueryOptions(activityId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const apiCompetencyDetail = (
    competencyId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CompetencyDetailOutput>> => {
    
    return axios.default.get(
      `/api/chartership/competencies/${competencyId}/`,options
    );
  }


export const getApiCompetencyDetailQueryKey = (competencyId: string,) => {
    return [`/api/chartership/competencies/${competencyId}/`] as const;
    }

    
export const getApiCompetencyDetailQueryOptions = <TData = Awaited<ReturnType<typeof apiCompetencyDetail>>, TError = AxiosError<unknown>>(competencyId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiCompetencyDetail>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getApiCompetencyDetailQueryKey(competencyId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiCompetencyDetail>>> = ({ signal }) => apiCompetencyDetail(competencyId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(competencyId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiCompetencyDetail>>, TError, TData> & { queryKey: QueryKey }
}

export type ApiCompetencyDetailQueryResult = NonNullable<Awaited<ReturnType<typeof apiCompetencyDetail>>>
export type ApiCompetencyDetailQueryError = AxiosError<unknown>

export const useApiCompetencyDetail = <TData = Awaited<ReturnType<typeof apiCompetencyDetail>>, TError = AxiosError<unknown>>(
 competencyId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiCompetencyDetail>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiCompetencyDetailQueryOptions(competencyId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const apiProfessionalBodyCourseList = (
    params?: ApiProfessionalBodyCourseListParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ApiProfessionalBodyCourseList200>> => {
    
    return axios.default.get(
      `/api/courses/`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getApiProfessionalBodyCourseListQueryKey = (params?: ApiProfessionalBodyCourseListParams,) => {
    return [`/api/courses/`, ...(params ? [params]: [])] as const;
    }

    
export const getApiProfessionalBodyCourseListQueryOptions = <TData = Awaited<ReturnType<typeof apiProfessionalBodyCourseList>>, TError = AxiosError<unknown>>(params?: ApiProfessionalBodyCourseListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiProfessionalBodyCourseList>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getApiProfessionalBodyCourseListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiProfessionalBodyCourseList>>> = ({ signal }) => apiProfessionalBodyCourseList(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiProfessionalBodyCourseList>>, TError, TData> & { queryKey: QueryKey }
}

export type ApiProfessionalBodyCourseListQueryResult = NonNullable<Awaited<ReturnType<typeof apiProfessionalBodyCourseList>>>
export type ApiProfessionalBodyCourseListQueryError = AxiosError<unknown>

export const useApiProfessionalBodyCourseList = <TData = Awaited<ReturnType<typeof apiProfessionalBodyCourseList>>, TError = AxiosError<unknown>>(
 params?: ApiProfessionalBodyCourseListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiProfessionalBodyCourseList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiProfessionalBodyCourseListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const apiMetricsDashboardCIOBSteppingStones = (
    userId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MetricsDashboardCIOBSteppingStonesOutput>> => {
    
    return axios.default.get(
      `/api/metrics/dashboard/ciob-stepping-stones/${userId}/`,options
    );
  }


export const getApiMetricsDashboardCIOBSteppingStonesQueryKey = (userId: string,) => {
    return [`/api/metrics/dashboard/ciob-stepping-stones/${userId}/`] as const;
    }

    
export const getApiMetricsDashboardCIOBSteppingStonesQueryOptions = <TData = Awaited<ReturnType<typeof apiMetricsDashboardCIOBSteppingStones>>, TError = AxiosError<unknown>>(userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiMetricsDashboardCIOBSteppingStones>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getApiMetricsDashboardCIOBSteppingStonesQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiMetricsDashboardCIOBSteppingStones>>> = ({ signal }) => apiMetricsDashboardCIOBSteppingStones(userId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiMetricsDashboardCIOBSteppingStones>>, TError, TData> & { queryKey: QueryKey }
}

export type ApiMetricsDashboardCIOBSteppingStonesQueryResult = NonNullable<Awaited<ReturnType<typeof apiMetricsDashboardCIOBSteppingStones>>>
export type ApiMetricsDashboardCIOBSteppingStonesQueryError = AxiosError<unknown>

export const useApiMetricsDashboardCIOBSteppingStones = <TData = Awaited<ReturnType<typeof apiMetricsDashboardCIOBSteppingStones>>, TError = AxiosError<unknown>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiMetricsDashboardCIOBSteppingStones>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiMetricsDashboardCIOBSteppingStonesQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const apiMetricsReportCore = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MetricsReportCoreOutput>> => {
    
    return axios.default.get(
      `/api/metrics/report/core/`,options
    );
  }


export const getApiMetricsReportCoreQueryKey = () => {
    return [`/api/metrics/report/core/`] as const;
    }

    
export const getApiMetricsReportCoreQueryOptions = <TData = Awaited<ReturnType<typeof apiMetricsReportCore>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiMetricsReportCore>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getApiMetricsReportCoreQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiMetricsReportCore>>> = ({ signal }) => apiMetricsReportCore({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiMetricsReportCore>>, TError, TData> & { queryKey: QueryKey }
}

export type ApiMetricsReportCoreQueryResult = NonNullable<Awaited<ReturnType<typeof apiMetricsReportCore>>>
export type ApiMetricsReportCoreQueryError = AxiosError<unknown>

export const useApiMetricsReportCore = <TData = Awaited<ReturnType<typeof apiMetricsReportCore>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiMetricsReportCore>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiMetricsReportCoreQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * View for users to login via email/password.
 */
export const apiEmailLogin = (
    emailLoginInput: EmailLoginInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EmailLoginOutput>> => {const formUrlEncoded = new URLSearchParams();
formUrlEncoded.append('username', emailLoginInput.username)
formUrlEncoded.append('password', emailLoginInput.password)

    
    return axios.default.post(
      `/api/account/email-login/`,
      formUrlEncoded,options
    );
  }



export const getApiEmailLoginMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiEmailLogin>>, TError,{data: EmailLoginInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof apiEmailLogin>>, TError,{data: EmailLoginInput}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiEmailLogin>>, {data: EmailLoginInput}> = (props) => {
          const {data} = props ?? {};

          return  apiEmailLogin(data,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApiEmailLoginMutationResult = NonNullable<Awaited<ReturnType<typeof apiEmailLogin>>>
    export type ApiEmailLoginMutationBody = EmailLoginInput
    export type ApiEmailLoginMutationError = AxiosError<unknown>

    export const useApiEmailLogin = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiEmailLogin>>, TError,{data: EmailLoginInput}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getApiEmailLoginMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete auth token from db and remove cookie.
 */
export const apiLogout = (
    apiLogoutBody: unknown, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/api/account/logout/`,
      apiLogoutBody,options
    );
  }



export const getApiLogoutMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiLogout>>, TError,{data: unknown}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof apiLogout>>, TError,{data: unknown}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiLogout>>, {data: unknown}> = (props) => {
          const {data} = props ?? {};

          return  apiLogout(data,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApiLogoutMutationResult = NonNullable<Awaited<ReturnType<typeof apiLogout>>>
    export type ApiLogoutMutationBody = unknown
    export type ApiLogoutMutationError = AxiosError<unknown>

    export const useApiLogout = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiLogout>>, TError,{data: unknown}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getApiLogoutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * View for a new user to set their password for the first time.
 */
export const apiAccountSetup = (
    accountSetupInput: AccountSetupInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AccountSetupOutput>> => {
    
    return axios.default.post(
      `/api/account/setup/`,
      accountSetupInput,options
    );
  }



export const getApiAccountSetupMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiAccountSetup>>, TError,{data: AccountSetupInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof apiAccountSetup>>, TError,{data: AccountSetupInput}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiAccountSetup>>, {data: AccountSetupInput}> = (props) => {
          const {data} = props ?? {};

          return  apiAccountSetup(data,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApiAccountSetupMutationResult = NonNullable<Awaited<ReturnType<typeof apiAccountSetup>>>
    export type ApiAccountSetupMutationBody = AccountSetupInput
    export type ApiAccountSetupMutationError = AxiosError<unknown>

    export const useApiAccountSetup = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiAccountSetup>>, TError,{data: AccountSetupInput}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getApiAccountSetupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * View for a user to change their password.
 */
export const apiChangePassword = (
    changePasswordInput: ChangePasswordInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/api/account/change-password/`,
      changePasswordInput,options
    );
  }



export const getApiChangePasswordMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiChangePassword>>, TError,{data: ChangePasswordInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof apiChangePassword>>, TError,{data: ChangePasswordInput}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiChangePassword>>, {data: ChangePasswordInput}> = (props) => {
          const {data} = props ?? {};

          return  apiChangePassword(data,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApiChangePasswordMutationResult = NonNullable<Awaited<ReturnType<typeof apiChangePassword>>>
    export type ApiChangePasswordMutationBody = ChangePasswordInput
    export type ApiChangePasswordMutationError = AxiosError<unknown>

    export const useApiChangePassword = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiChangePassword>>, TError,{data: ChangePasswordInput}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getApiChangePasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * View for a user to request an link via email for their password to be reset.
 */
export const apiForgotPassword = (
    forgotPasswordInput: ForgotPasswordInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/api/account/forgot-password/`,
      forgotPasswordInput,options
    );
  }



export const getApiForgotPasswordMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiForgotPassword>>, TError,{data: ForgotPasswordInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof apiForgotPassword>>, TError,{data: ForgotPasswordInput}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiForgotPassword>>, {data: ForgotPasswordInput}> = (props) => {
          const {data} = props ?? {};

          return  apiForgotPassword(data,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApiForgotPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof apiForgotPassword>>>
    export type ApiForgotPasswordMutationBody = ForgotPasswordInput
    export type ApiForgotPasswordMutationError = AxiosError<unknown>

    export const useApiForgotPassword = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiForgotPassword>>, TError,{data: ForgotPasswordInput}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getApiForgotPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * View for a user to update their password through a link sent to them via email.
 */
export const apiResetPassword = (
    resetPasswordInput: ResetPasswordInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/api/account/reset-password/`,
      resetPasswordInput,options
    );
  }



export const getApiResetPasswordMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiResetPassword>>, TError,{data: ResetPasswordInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof apiResetPassword>>, TError,{data: ResetPasswordInput}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiResetPassword>>, {data: ResetPasswordInput}> = (props) => {
          const {data} = props ?? {};

          return  apiResetPassword(data,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApiResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof apiResetPassword>>>
    export type ApiResetPasswordMutationBody = ResetPasswordInput
    export type ApiResetPasswordMutationError = AxiosError<unknown>

    export const useApiResetPassword = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiResetPassword>>, TError,{data: ResetPasswordInput}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getApiResetPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiUserCreate = (
    userCreateInput: UserCreateInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserCreateOutput>> => {
    
    return axios.default.post(
      `/api/users/create/`,
      userCreateInput,options
    );
  }



export const getApiUserCreateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUserCreate>>, TError,{data: UserCreateInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof apiUserCreate>>, TError,{data: UserCreateInput}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUserCreate>>, {data: UserCreateInput}> = (props) => {
          const {data} = props ?? {};

          return  apiUserCreate(data,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApiUserCreateMutationResult = NonNullable<Awaited<ReturnType<typeof apiUserCreate>>>
    export type ApiUserCreateMutationBody = UserCreateInput
    export type ApiUserCreateMutationError = AxiosError<unknown>

    export const useApiUserCreate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUserCreate>>, TError,{data: UserCreateInput}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getApiUserCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiUserUpdate = (
    userId: string,
    userUpdateInput: UserUpdateInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserUpdateOutput>> => {
    
    return axios.default.post(
      `/api/users/${userId}/update/`,
      userUpdateInput,options
    );
  }



export const getApiUserUpdateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUserUpdate>>, TError,{userId: string;data: UserUpdateInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof apiUserUpdate>>, TError,{userId: string;data: UserUpdateInput}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUserUpdate>>, {userId: string;data: UserUpdateInput}> = (props) => {
          const {userId,data} = props ?? {};

          return  apiUserUpdate(userId,data,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApiUserUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof apiUserUpdate>>>
    export type ApiUserUpdateMutationBody = UserUpdateInput
    export type ApiUserUpdateMutationError = AxiosError<unknown>

    export const useApiUserUpdate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUserUpdate>>, TError,{userId: string;data: UserUpdateInput}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getApiUserUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiUserDelete = (
    userId: string,
    apiUserDeleteBody: unknown, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/api/users/${userId}/delete/`,
      apiUserDeleteBody,options
    );
  }



export const getApiUserDeleteMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUserDelete>>, TError,{userId: string;data: unknown}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof apiUserDelete>>, TError,{userId: string;data: unknown}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUserDelete>>, {userId: string;data: unknown}> = (props) => {
          const {userId,data} = props ?? {};

          return  apiUserDelete(userId,data,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApiUserDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof apiUserDelete>>>
    export type ApiUserDeleteMutationBody = unknown
    export type ApiUserDeleteMutationError = AxiosError<unknown>

    export const useApiUserDelete = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUserDelete>>, TError,{userId: string;data: unknown}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getApiUserDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create an activity based on a chartership activity.
 */
export const apiActivityCreate = (
    activityCreateInput: ActivityCreateInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ActivityCreateOutput>> => {
    
    return axios.default.post(
      `/api/activities/create/`,
      activityCreateInput,options
    );
  }



export const getApiActivityCreateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiActivityCreate>>, TError,{data: ActivityCreateInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof apiActivityCreate>>, TError,{data: ActivityCreateInput}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiActivityCreate>>, {data: ActivityCreateInput}> = (props) => {
          const {data} = props ?? {};

          return  apiActivityCreate(data,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApiActivityCreateMutationResult = NonNullable<Awaited<ReturnType<typeof apiActivityCreate>>>
    export type ApiActivityCreateMutationBody = ActivityCreateInput
    export type ApiActivityCreateMutationError = AxiosError<unknown>

    export const useApiActivityCreate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiActivityCreate>>, TError,{data: ActivityCreateInput}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getApiActivityCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update an activity. Only the creating user can update the activity.
 */
export const apiActivityUpdate = (
    activityId: string,
    activityUpdateInput: ActivityUpdateInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ActivityUpdateOutput>> => {
    
    return axios.default.post(
      `/api/activities/${activityId}/update/`,
      activityUpdateInput,options
    );
  }



export const getApiActivityUpdateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiActivityUpdate>>, TError,{activityId: string;data: ActivityUpdateInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof apiActivityUpdate>>, TError,{activityId: string;data: ActivityUpdateInput}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiActivityUpdate>>, {activityId: string;data: ActivityUpdateInput}> = (props) => {
          const {activityId,data} = props ?? {};

          return  apiActivityUpdate(activityId,data,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApiActivityUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof apiActivityUpdate>>>
    export type ApiActivityUpdateMutationBody = ActivityUpdateInput
    export type ApiActivityUpdateMutationError = AxiosError<unknown>

    export const useApiActivityUpdate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiActivityUpdate>>, TError,{activityId: string;data: ActivityUpdateInput}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getApiActivityUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete an activity. Only the creating user can delete the activity.
 */
export const apiActivityDelete = (
    activityId: string,
    apiActivityDeleteBody: unknown, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/api/activities/${activityId}/delete/`,
      apiActivityDeleteBody,options
    );
  }



export const getApiActivityDeleteMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiActivityDelete>>, TError,{activityId: string;data: unknown}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof apiActivityDelete>>, TError,{activityId: string;data: unknown}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiActivityDelete>>, {activityId: string;data: unknown}> = (props) => {
          const {activityId,data} = props ?? {};

          return  apiActivityDelete(activityId,data,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApiActivityDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof apiActivityDelete>>>
    export type ApiActivityDeleteMutationBody = unknown
    export type ApiActivityDeleteMutationError = AxiosError<unknown>

    export const useApiActivityDelete = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiActivityDelete>>, TError,{activityId: string;data: unknown}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getApiActivityDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Export one or more activities to a CSV file.
If activity_id is not passed, export all activities.
 */
export const apiActivityExport = (
    activityExportInput: ActivityExportInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/api/activities/export`,
      activityExportInput,options
    );
  }



export const getApiActivityExportMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiActivityExport>>, TError,{data: ActivityExportInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof apiActivityExport>>, TError,{data: ActivityExportInput}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiActivityExport>>, {data: ActivityExportInput}> = (props) => {
          const {data} = props ?? {};

          return  apiActivityExport(data,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApiActivityExportMutationResult = NonNullable<Awaited<ReturnType<typeof apiActivityExport>>>
    export type ApiActivityExportMutationBody = ActivityExportInput
    export type ApiActivityExportMutationError = AxiosError<unknown>

    export const useApiActivityExport = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiActivityExport>>, TError,{data: ActivityExportInput}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getApiActivityExportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiActivityCommentCreate = (
    activityId: string,
    activityCommentCreateInput: ActivityCommentCreateInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ActivityCommentCreateOutput>> => {
    
    return axios.default.post(
      `/api/comments/${activityId}/create/`,
      activityCommentCreateInput,options
    );
  }



export const getApiActivityCommentCreateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiActivityCommentCreate>>, TError,{activityId: string;data: ActivityCommentCreateInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof apiActivityCommentCreate>>, TError,{activityId: string;data: ActivityCommentCreateInput}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiActivityCommentCreate>>, {activityId: string;data: ActivityCommentCreateInput}> = (props) => {
          const {activityId,data} = props ?? {};

          return  apiActivityCommentCreate(activityId,data,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApiActivityCommentCreateMutationResult = NonNullable<Awaited<ReturnType<typeof apiActivityCommentCreate>>>
    export type ApiActivityCommentCreateMutationBody = ActivityCommentCreateInput
    export type ApiActivityCommentCreateMutationError = AxiosError<unknown>

    export const useApiActivityCommentCreate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiActivityCommentCreate>>, TError,{activityId: string;data: ActivityCommentCreateInput}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getApiActivityCommentCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiExternalLinkMetricLogView = (
    externalLinkMetricLogViewInput: ExternalLinkMetricLogViewInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/api/metrics/log/external-link/`,
      externalLinkMetricLogViewInput,options
    );
  }



export const getApiExternalLinkMetricLogViewMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiExternalLinkMetricLogView>>, TError,{data: ExternalLinkMetricLogViewInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof apiExternalLinkMetricLogView>>, TError,{data: ExternalLinkMetricLogViewInput}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiExternalLinkMetricLogView>>, {data: ExternalLinkMetricLogViewInput}> = (props) => {
          const {data} = props ?? {};

          return  apiExternalLinkMetricLogView(data,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApiExternalLinkMetricLogViewMutationResult = NonNullable<Awaited<ReturnType<typeof apiExternalLinkMetricLogView>>>
    export type ApiExternalLinkMetricLogViewMutationBody = ExternalLinkMetricLogViewInput
    export type ApiExternalLinkMetricLogViewMutationError = AxiosError<unknown>

    export const useApiExternalLinkMetricLogView = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiExternalLinkMetricLogView>>, TError,{data: ExternalLinkMetricLogViewInput}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getApiExternalLinkMetricLogViewMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiContactCreate = (
    contactCreateInput: ContactCreateInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/api/contact/form/`,
      contactCreateInput,options
    );
  }



export const getApiContactCreateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiContactCreate>>, TError,{data: ContactCreateInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof apiContactCreate>>, TError,{data: ContactCreateInput}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiContactCreate>>, {data: ContactCreateInput}> = (props) => {
          const {data} = props ?? {};

          return  apiContactCreate(data,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApiContactCreateMutationResult = NonNullable<Awaited<ReturnType<typeof apiContactCreate>>>
    export type ApiContactCreateMutationBody = ContactCreateInput
    export type ApiContactCreateMutationError = AxiosError<unknown>

    export const useApiContactCreate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiContactCreate>>, TError,{data: ContactCreateInput}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getApiContactCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiUserFeedbackCreate = (
    userFeedbackCreateInput: UserFeedbackCreateInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/api/support/feedback/`,
      userFeedbackCreateInput,options
    );
  }



export const getApiUserFeedbackCreateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUserFeedbackCreate>>, TError,{data: UserFeedbackCreateInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof apiUserFeedbackCreate>>, TError,{data: UserFeedbackCreateInput}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUserFeedbackCreate>>, {data: UserFeedbackCreateInput}> = (props) => {
          const {data} = props ?? {};

          return  apiUserFeedbackCreate(data,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApiUserFeedbackCreateMutationResult = NonNullable<Awaited<ReturnType<typeof apiUserFeedbackCreate>>>
    export type ApiUserFeedbackCreateMutationBody = UserFeedbackCreateInput
    export type ApiUserFeedbackCreateMutationError = AxiosError<unknown>

    export const useApiUserFeedbackCreate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUserFeedbackCreate>>, TError,{data: UserFeedbackCreateInput}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getApiUserFeedbackCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiUserHelpRequestCreate = (
    userHelpRequestCreateInput: UserHelpRequestCreateInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/api/support/help/`,
      userHelpRequestCreateInput,options
    );
  }



export const getApiUserHelpRequestCreateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUserHelpRequestCreate>>, TError,{data: UserHelpRequestCreateInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof apiUserHelpRequestCreate>>, TError,{data: UserHelpRequestCreateInput}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUserHelpRequestCreate>>, {data: UserHelpRequestCreateInput}> = (props) => {
          const {data} = props ?? {};

          return  apiUserHelpRequestCreate(data,axiosOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApiUserHelpRequestCreateMutationResult = NonNullable<Awaited<ReturnType<typeof apiUserHelpRequestCreate>>>
    export type ApiUserHelpRequestCreateMutationBody = UserHelpRequestCreateInput
    export type ApiUserHelpRequestCreateMutationError = AxiosError<unknown>

    export const useApiUserHelpRequestCreate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUserHelpRequestCreate>>, TError,{data: UserHelpRequestCreateInput}, TContext>, axios?: AxiosRequestConfig}
) => {

      const mutationOptions = getApiUserHelpRequestCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    