import { useEffect } from "react";

import { cn } from "@/lib/utils";
import { getDocumentTitle } from "@/utils/document";

export type PageLayoutProps = {
  children: React.ReactNode;
  title?: string;
  className?: string;
};

export const PageLayout = ({
  title,
  children,
  className = "max-w-7xl px-4 sm:px-6 lg:px-8",
}: PageLayoutProps) => {
  useEffect(() => {
    document.title = getDocumentTitle(title);
  }, [title]);

  return <div className={cn("w-full mx-auto", className)}>{children}</div>;
};
