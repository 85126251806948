class TokenStorage {
  private static instance: TokenStorage;
  private tokenKey = "my-chartered-auth-token";

  private constructor() {
    // Private constructor to prevent instantiation from outside the class
  }

  public static getInstance(): TokenStorage {
    if (!TokenStorage.instance) {
      TokenStorage.instance = new TokenStorage();
    }
    return TokenStorage.instance;
  }

  public getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  public setToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  public removeToken(): void {
    localStorage.removeItem(this.tokenKey);
  }
}

export { TokenStorage };
