import { useCallback } from "react";

import { Link, useLocation } from "react-router-dom";
import { z } from "zod";

import { Form, FormInput } from "@/components/form";

import { useAuth } from "../hooks/useAuth";
import { AuthLayout } from "../layout/AuthLayout";

const formSchema = z.object({
  username: z.string().min(1, "Required").email("Enter a valid email"),
  password: z.string().min(1, "Required"),
});

type FormSchemaType = z.infer<typeof formSchema>;

export function Login() {
  const location = useLocation();
  const { login } = useAuth();

  const from = location.state?.from?.pathname || "/";

  const handleSubmit = useCallback(
    async (data: FormSchemaType) => await login(data, from),
    [login, from]
  );

  return (
    <AuthLayout heading="Sign in to your account" title="Sign in">
      <Form<FormSchemaType>
        className="space-y-4"
        formSchema={formSchema}
        submitButtonClassName="w-full"
        submitText="Login"
        onSubmit={handleSubmit}
      >
        {({ control }) => (
          <>
            <FormInput control={control} label="Email" name="username" />
            <FormInput
              control={control}
              label="Password"
              name="password"
              type="password"
            />
          </>
        )}
      </Form>
      <Link
        className="text-sm leading-6 w-full text-center"
        to="/accounts/forgot-password"
      >
        Forgot password?
      </Link>
    </AuthLayout>
  );
}
