import { TokenStorage } from "@/features/auth";
import axios, { AxiosRequestHeaders, InternalAxiosRequestConfig } from "axios";

axios.interceptors.request.use((config: InternalAxiosRequestConfig<any>) => ({
  ...config,
  baseURL: import.meta.env.VITE_BASE_URL,
  headers: {
    ...config.headers,
    Authorization: `Token ${TokenStorage.getInstance().getToken()}`,
  } as AxiosRequestHeaders,
}));

export * from "./api/api";
export * from "./model";
