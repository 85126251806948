import { useCallback, useState } from "react";

import { Link } from "react-router-dom";
import { z } from "zod";

import { useApiForgotPassword } from "@/api";
import { Header } from "@/components/elements";
import { Form, FormInput } from "@/components/form";
import { useToast } from "@/components/ui/use-toast";

import { AuthLayout } from "../layout/AuthLayout";

const formSchema = z.object({
  email: z
    .string()
    .min(1, "Please enter your email")
    .email("Please enter a valid email"),
});

export type FormSchemaType = z.infer<typeof formSchema>;

export const ForgotPassword = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { mutateAsync: forgotPassword } = useApiForgotPassword();
  const { toast } = useToast();

  const handleSubmit = useCallback(
    async (data: FormSchemaType) => {
      await forgotPassword({ data });
      toast({
        title: "Password reset email sent",
        description: "Please check your email to reset your password",
      });
      setIsSubmitted(true);
    },
    [forgotPassword, setIsSubmitted, toast]
  );

  return (
    <AuthLayout
      heading="Forgot Password"
      subheading="Please enter your account email. A password reset email will be sent to you to reset your password."
      title="Forgot Password"
    >
      {isSubmitted ? (
        <Submitted />
      ) : (
        <Form<FormSchemaType>
          className="space-y-4"
          cleanFormOnSuccess={true}
          formSchema={formSchema}
          submitButtonClassName="w-full"
          submitText="Send reset email"
          onSubmit={handleSubmit}
        >
          {({ control }) => (
            <>
              <FormInput
                control={control}
                label="Email"
                name="email"
                placeholder="Please enter your email"
                type="email"
              />
            </>
          )}
        </Form>
      )}
      <Link
        className="text-sm leading-6 w-full text-center"
        to="/accounts/login"
      >
        <span aria-hidden="true" className="mr-2">
          &larr;
        </span>
        Back to sign in
      </Link>
    </AuthLayout>
  );
};

const Submitted = () => {
  return (
    <div className="bg-white border rounded-md p-6">
      <Header
        subtitle="Please check your email for a password reset link. You may also need to check your spam folder."
        title="Password reset email sent"
      />
    </div>
  );
};