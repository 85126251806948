import { useCallback, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import type { AxiosError } from "axios";
import type React from "react";
import type {
  FieldValues,
  SubmitHandler,
  UseFormProps,
  UseFormReturn,
} from "react-hook-form";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import type { z, ZodType, ZodTypeDef } from "zod";

import { cn } from "@/lib/utils";
import { parseApiError } from "@/utils/errors";

import { Spinner } from "../elements";
import { Button } from "../ui/button";
import { Form as BaseForm } from "../ui/form";
import type { ToastType } from "../ui/use-toast";
import { useToast } from "../ui/use-toast";
import { FormError } from "./FormError";
import { setFormErrors } from "./utils";

export type FormProps<TFormValues extends FieldValues> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formSchema: ZodType<any, ZodTypeDef, unknown>;
  onSubmit: SubmitHandler<TFormValues>;
  children: (methods: UseFormReturn<TFormValues>) => React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  to?: string | ((row: any) => string);
  submitToast?: ToastType;
  options?: UseFormProps<TFormValues>;
  className?: string;
  submitButtonClassName?: string;
  submitText?: string;
  cleanFormOnSuccess?: boolean;
  submitButtonVariant?:
    | "link"
    | "default"
    | "destructive"
    | "outline"
    | "secondary"
    | "ghost"
    | null
    | undefined;
};

export const Form = <
  TFormValues extends Record<string, unknown> = Record<string, unknown>
>({
  formSchema,
  onSubmit,
  children,
  options,
  to,
  submitToast,
  submitButtonVariant,
  className = "",
  submitText = "Submit",
  submitButtonClassName = "",
  cleanFormOnSuccess = false,
}: FormProps<TFormValues>) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    ...options,
    resolver: zodResolver(formSchema),
  });

  const handleSubmit = useCallback(
    async (data: TFormValues) => {
      setIsLoading(true);
      try {
        const returnData = await onSubmit(data);

        if (cleanFormOnSuccess) {
          form.reset({});
        }

        if (submitToast) {
          toast(submitToast);
        }

        if (to) {
          if (typeof to === "string") {
            navigate(to);
          } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            navigate(to((returnData as any).data));
          }
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        const { message, fieldErrors } = parseApiError(error as AxiosError);
        setFormErrors(form, message, fieldErrors);
      }
    },
    [cleanFormOnSuccess, form, navigate, onSubmit, submitToast, to, toast]
  );

  return (
    <>
      {form.formState.errors["root"] && (
        <div className="my-3">
          <FormError error={form.formState.errors["root"].message as string} />
        </div>
      )}
      <BaseForm {...form}>
        <form
          className={cn("space-y-8", className)}
          onSubmit={form.handleSubmit(handleSubmit)}
        >
          <>
            {children(form)}
            {!options?.disabled && (
              <Button
                className={cn("mt-4", submitButtonClassName)}
                disabled={isLoading}
                type="submit"
                variant={submitButtonVariant}
              >
                {isLoading && <Spinner />}
                {submitText}
              </Button>
            )}
          </>
        </form>
      </BaseForm>
    </>
  );
};
