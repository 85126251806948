import { useApiChartershipList } from "@/api";
import type { FormComboboxProps, OptionsType } from "@/components/form";
import { FormCombobox } from "@/components/form";

type ChartershipSelectProps = Omit<
  FormComboboxProps,
  "data" | "isLoading" | "options"
>;

export function ChartershipCombobox(props: ChartershipSelectProps) {
  const { isLoading, data } = useApiChartershipList();

  const options: OptionsType =
    data?.data.results?.map((item) => ({
      label: item.name,
      value: item.id,
    })) ?? [];

  return (
    <FormCombobox
      isLoading={isLoading}
      options={options}
      placeholder="Select chartership..."
      {...props}
    />
  );
}
