import { useEffect } from "react";

import { Logo } from "@/components/elements";
import type { PageLayoutProps } from "@/components/layout";
import { getDocumentTitle } from "@/utils/document";

type AuthLayoutProps = {
  heading: string;
  subheading?: string;
} & PageLayoutProps;

export const AuthLayout = ({
  title,
  heading,
  subheading,
  children,
}: AuthLayoutProps) => {
  useEffect(() => {
    document.title = getDocumentTitle(title);
  }, [title]);

  return (
    <div className="min-h-screen bg-white">
      <div className="container flex h-screen w-screen flex-col items-center justify-center">
        <div className="mx-auto">
          <Logo className="h-24" />
        </div>
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
          <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-2xl font-normal tracking-tight">{heading}</h1>
            <p className="text-sm font-light text-muted-foreground">
              {subheading}
            </p>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
