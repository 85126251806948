import { useCallback } from "react";

import { z } from "zod";

import { Form, FormInput } from "@/components/form";
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

const formSchema = z
  .object({
    confirm: z.string().min(1, "Required"),
  })
  .refine((data) => data.confirm === "confirm", {
    message: `Please enter "confirm" to delete.`,
    path: ["confirm"],
  });

export type FormSchemaType = z.infer<typeof formSchema>;

type DeleteModelProps = {
  title: string;
  subtitle: string;
  onDelete: () => Promise<void>;
};

export const DeleteModel = ({
  title,
  subtitle,
  onDelete,
}: DeleteModelProps) => {
  const handleDelete = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (_data: FormSchemaType) => await onDelete(),
    [onDelete]
  );

  return (
    <DialogContent className="sm:max-w-[425px]">
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
        <DialogDescription>{subtitle}</DialogDescription>
      </DialogHeader>
      <div className="flex flex-col">
        <Form<FormSchemaType>
          className="mt-2 space-y-4"
          formSchema={formSchema}
          submitButtonVariant="destructive"
          submitText="Permanently Delete"
          onSubmit={handleDelete}
        >
          {({ control }) => (
            <>
              <FormInput
                control={control}
                name="confirm"
                placeholder={`Enter "confirm" to delete`}
              />
            </>
          )}
        </Form>
      </div>
    </DialogContent>
  );
};
