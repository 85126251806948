import type { UserDetailMeOutput } from "@/api";
import { apiEmailLogin, apiUserDetailMe } from "@/api";

import type { LoginCredentialsType } from "../types";
import { TokenStorage } from "./token";

const getUser = async (): Promise<UserDetailMeOutput | null> => {
  try {
    const response = await apiUserDetailMe();
    return response.data;
  } catch (error) {
    return null;
  }
};

const login = async (
  data: LoginCredentialsType
): Promise<UserDetailMeOutput> => {
  const tokenData = await apiEmailLogin(data);
  const token = tokenData.data.token;

  if (!token) {
    throw new Error("Something went wrong. Please try again.");
  }

  return await setToken(token);
};

const setToken = async (token: string): Promise<UserDetailMeOutput> => {
  TokenStorage.getInstance().setToken(token);

  const user = await getUser();

  if (!user) {
    throw new Error("Something went wrong. Please try again.");
  }

  return user;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logout = async (logout: any): Promise<void> => {
  await logout({});
  TokenStorage.getInstance().removeToken();
  window.location.assign(window.location.origin);
};

export const authService = {
  getUser,
  login,
  setToken,
  logout,
};
