import { memo } from "react";

import { ChevronLeft } from "lucide-react";
import { Link, useLocation } from "react-router-dom";

import { Button } from "@/components/ui/button";

export interface HeaderProps {
  title?: string;
  subtitle?: string;
  actions?: {
    text: string;
    to: string;
    icon?: React.ElementType;
  }[];
  components?: React.ReactNode[];
  backTo?: string;
}

export const Header = memo(
  ({ title, subtitle, actions = [], components = [], backTo }: HeaderProps) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const from = queryParams.get("from");
    const backPath = from ?? backTo;

    return (
      <>
        {backPath && (
          <Button
            className="-ml-2.5 pl-2 pr-3.5 font-normal"
            variant="ghost"
            asChild
          >
            <Link to={backPath}>
              <ChevronLeft className="mr-2 h-4 w-4" />
              Back
            </Link>
          </Button>
        )}
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-normal tracking-tight">{title}</h2>
            <p className="text-sm font-light text-muted-foreground">
              {subtitle}
            </p>
          </div>
          {(actions.length > 0 || components.length > 0) && (
            <div className="mb-auto mt-4 flex flex-shrink-0 md:mt-0 md:ml-4 space-x-2">
              {components.map((component) => component)}
              {actions.map((action) => (
                <Button asChild={true} key={action.to}>
                  <Link className="justify-start" to={action.to}>
                    {action.icon && <action.icon className="mr-2 h-4 w-4" />}
                    {action.text}
                  </Link>
                </Button>
              ))}
            </div>
          )}
        </div>
      </>
    );
  }
);

Header.displayName = "Header";
