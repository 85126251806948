import { wrapUseRoutes } from "@sentry/react";
import { useRoutes } from "react-router-dom";

import { useAuth } from "@/features/auth";

import { privateRoutes } from "./private";
import { publicRoutes } from "./public";

const useSentryRoutes = wrapUseRoutes(useRoutes);

export const AppRoutes = () => {
  const { user } = useAuth();

  const routes = user ? privateRoutes : publicRoutes;

  const element = useSentryRoutes(routes);

  return <>{element}</>;
};
