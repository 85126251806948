import type { AxiosError } from "axios";

export const parseApiError = (error: AxiosError | undefined) => {
  if (!error) {
    return {
      message: "Something went wrong. Please try again.",
      fieldErrors: {},
    };
  }

  const errors: {
    message?: string;
    extra?: {
      fields?: Record<string, string | string[]>;
    };
  } = error.response?.data ?? {};

  const errorMsg = errors["message"];
  const fieldErrors = errors["extra"]?.fields;

  return {
    message: errorMsg,
    fieldErrors: fieldErrors ?? {},
  };
};
