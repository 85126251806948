import type { UseFormSetValue } from "react-hook-form";

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select as BaseSelect,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import type { ControlledFieldProps } from "../types";
import type { OptionsType } from "../types";

export type FormSelectProps = ControlledFieldProps & {
  options: OptionsType;
  isLoading?: boolean;
  allowNull?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue?: UseFormSetValue<any>;
};

export const FormSelect = ({
  name,
  label,
  description,
  control,
  options,
  placeholder = "Select...",
  isLoading,
}: FormSelectProps) => {
  return (
    <FormField
      control={control}
      name={name}
      // eslint-disable-next-line react/jsx-no-bind
      render={({ field }) => (
        <FormItem className="flex flex-col font-light">
          <div className="flex justify-between content-center">
            {label && <FormLabel>{label}</FormLabel>}
            {/* {allowNull && field.value && !field.disabled && setValue && (
              <div
                className="leading-none text-sm font-light text-right hover:underline cursor-pointer"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => setValue(name, undefined)}
              >
                clear
              </div>
            )} */}
          </div>
          <BaseSelect
            defaultValue={field.value}
            disabled={isLoading || field.disabled}
            onValueChange={field.onChange}
          >
            <FormControl>
              <SelectTrigger>
                <SelectValue
                  placeholder={isLoading ? "Loading..." : placeholder}
                />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              <SelectGroup>
                {options.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </BaseSelect>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
