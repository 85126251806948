import { Suspense } from "react";

import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

import { Spinner } from "@/components/elements";
import { MainLayout } from "@/components/layout";
import { useAuth } from "@/features/auth";
import { lazyImport } from "@/utils/lazyImport";

const { MetricsRoutes } = lazyImport(
  () => import("@/features/metrics"),
  "MetricsRoutes"
);

const { ActivitiesRoutes } = lazyImport(
  () => import("@/features/activities"),
  "ActivitiesRoutes"
);

const { CommentsRoutes } = lazyImport(
  () => import("@/features/activities"),
  "CommentsRoutes"
);

const { ChartershipRoutes } = lazyImport(
  () => import("@/features/chartership"),
  "ChartershipRoutes"
);

const { CoursesRoutes } = lazyImport(
  () => import("@/features/chartership"),
  "CoursesRoutes"
);

const { ExternalLinkRedirect } = lazyImport(
  () => import("@/features/metrics"),
  "ExternalLinkRedirect"
);

const { MenteesRoutes } = lazyImport(
  () => import("@/features/manage"),
  "MenteesRoutes"
);

const { UpdatesRoutes } = lazyImport(
  () => import("@/features/manage"),
  "UpdatesRoutes"
);

const { UsersRoutes } = lazyImport(
  () => import("@/features/accounts"),
  "UsersRoutes"
);

const { ProfileRoutes } = lazyImport(
  () => import("@/features/auth"),
  "ProfileRoutes"
);

const { SupportRoutes } = lazyImport(
  () => import("@/features/support"),
  "SupportRoutes"
);

export const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="non-ideal-state">
            <Spinner />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

const IndexRoute = () => {
  const { user } = useAuth();

  if (!user || user?.account_type === "USER") {
    return <Navigate to="/dashboard" />;
  }
  return <Navigate to="/mentees" />;
};

export const privateRoutes = [
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/", element: <IndexRoute /> },
      { path: "/*", element: <MetricsRoutes /> },
      { path: "/activities/*", element: <ActivitiesRoutes /> },
      { path: "/comments/*", element: <CommentsRoutes /> },
      { path: "/chartership/*", element: <ChartershipRoutes /> },
      { path: "/courses/*", element: <CoursesRoutes /> },
      { path: "/mentees/*", element: <MenteesRoutes /> },
      { path: "/updates/*", element: <UpdatesRoutes /> },
      { path: "/users/*", element: <UsersRoutes /> },
      { path: "/profile/*", element: <ProfileRoutes /> },
      { path: "/support/*", element: <SupportRoutes /> },
      { path: "*", element: <Navigate to="/" /> },
    ],
  },
  {
    path: "/external-link/redirect",
    element: (
      <Suspense
        fallback={
          <div className="non-ideal-state">
            <Spinner />
          </div>
        }
      >
        <ExternalLinkRedirect />
      </Suspense>
    ),
  },
];
