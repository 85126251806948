import { useCallback } from "react";

import { Button } from "../ui/button";


export const ErrorFallback = () => {
  const handleClick = useCallback(
    () => window.location.assign(window.location.origin),
    []
  );

  return (
    <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-indigo-600">Error</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Something went wrong!
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600 max-w-xl">
          We apologise for the inconvenience, but an unexpected error has
          occurred. Our team has been notified and is working diligently to
          resolve the issue.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button className="w-40" onClick={handleClick}>
            Go back home
          </Button>
        </div>
      </div>
    </main>
  );
};
