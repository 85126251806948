import React, { useMemo, useState } from "react";
import { useCallback, useEffect } from "react";

import { useApiLogout, type UserDetailMeOutput } from "@/api";

import { authService } from "../services";
import type { LoginCredentialsType } from "../types";

interface AuthContextType {
  user: UserDetailMeOutput | null;
  login: (credentials: LoginCredentialsType, to: string) => Promise<void>;
  logout: () => void;
  setToken: (token: string, to?: string) => Promise<void>;
}

export const AuthContext = React.createContext<AuthContextType>(
  {} as AuthContextType
);

export function AuthProvider({
  children,
  fallback,
}: {
  children: React.ReactNode;
  fallback: React.ReactNode;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<UserDetailMeOutput | null>(null);
  const { mutateAsync: apiLogout } = useApiLogout();

  useEffect(() => {
    authService.getUser().then((user) => {
      setUser(user);
      setIsLoading(false);
    });
  }, []);

  const login = useCallback(
    async (credentials: LoginCredentialsType, to: string) => {
      const user = await authService.login(credentials);
      setUser(user);
      window.location.assign(to);
    },
    []
  );

  const setToken = useCallback(async (token: string, to?: string) => {
    const user = await authService.setToken(token);
    setUser(user);
    if (to) {
      window.location.assign(to);
    }
  }, [])

  const logout = useCallback(() => {
    authService.logout(apiLogout);
    setUser(null);
  }, [apiLogout]);

  const value = useMemo(
    () => ({
      user,
      login,
      setToken,
      logout,
    }),
    [login, setToken, logout, user]
  );

  if (isLoading) {
    return fallback;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
