import { cn } from "@/lib/utils";

type NonIdealStateProps = {
  children: React.ReactNode;
  className?: string;
};

export const NonIdealState = ({
  children,
  className = "",
}: NonIdealStateProps) => {
  return (
    <div
      className={cn(
        "h-screen w-screen flex items-center content-center justify-center",
        className
      )}
    >
      {children}
    </div>
  );
};
