import type { ApiUserListParams } from "@/api";
import { useApiUserList } from "@/api";
import type { FormComboboxProps, OptionsType } from "@/components/form";
import { FormCombobox } from "@/components/form";

import { formatName } from "../utils/format";

type UserComboboxProps = {
  params?: ApiUserListParams;
} & Omit<FormComboboxProps, "data" | "isLoading" | "options">;

export function UserCombobox(props: UserComboboxProps) {
  const { isLoading, data } = useApiUserList(props.params);

  const options: OptionsType =
    data?.data.results?.map((item) => ({
      label: formatName(item),
      value: item.id,
    })) ?? [];

  return (
    <FormCombobox
      isLoading={isLoading}
      options={options}
      placeholder="Select a user..."
      {...props}
    />
  );
}
